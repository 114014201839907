<!--资料上传页面-->
<template>
  <div class="box">
    <div class="nav_top">
      <div class="top"><top-nav></top-nav></div>
      <!-- <h1>环保装备交易平台</h1>
      <h2>服务环境产业上下游</h2> -->
    </div>
    <div class="content">
      <el-form ref="form" :model="sizeForm" label-width="120px" size="mini">
        <div class="box_flex">
          <el-form-item label="项目名称:">
            <input
              class="input_box"
              type="text"
              placeholder="请输入项目名称"
              v-model="sizeForm.name"
            />
          </el-form-item>
        </div>
        <div class="box_flex2">
          <div class="left">
            <el-form-item label="供应商名称:">
              <input
                class="input_box"
                type="text"
                placeholder="请输入供应商名称"
                v-model="sizeForm.supplierName"
              />
            </el-form-item>
          </div>
          <div class="right">
            <el-form-item label="出售商品名称:">
              <input
                class="input_box"
                type="text"
                placeholder="请输入出售商品名称"
                v-model="sizeForm.sellGoodsName"
              />
            </el-form-item>
          </div>
        </div>
        <div class="box_flex2">
          <div class="left">
            <el-form-item label="甲方名称:">
              <input
                class="input_box"
                type="text"
                placeholder="请输入甲方名称"
                v-model="sizeForm.firstParty"
              />
            </el-form-item>
          </div>
          <div class="right">
            <el-form-item label="乙方名称:">
              <input
                class="input_box"
                type="text"
                placeholder="请输入乙方名称"
                v-model="sizeForm.partyB"
              />
            </el-form-item>
          </div>
        </div>
        <div class="box_flex2">
          <div class="left">
            <el-form-item label="签约时间:">
              <div class="option_box">
                <div class="title3">{{sizeForm.signingTime}}</div>
                <!-- <div class="signing_time">
                  <el-date-picker
                    v-model="sizeForm.signingTime"
                    :editable="false"
                    type="date"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </div>
                <div class="img1">
                  <img
                    src="../assets/supplier/2.png"
                    width="100%"
                    height="100%"
                  />
                </div> -->
              </div>
            </el-form-item>
          </div>
          <div class="right">
            <el-form-item label="签约金额:">
              <input
                class="input_box"
                type="number"
                placeholder="请输入签约金额"
                v-model="sizeForm.money"
              />
            </el-form-item>
          </div>
        </div>
        <div class="box_flex">
          <el-form-item label="上传合同:">
            <div class="contract_box">
              <div v-if="fileList&&fileList.length > 0" class="flex2">
                <div class="imgs2">
                  <img src="../assets/file2.png">
                </div>
                <a
                  :href="tmp.url"
                  v-for="(tmp, index) in fileList"
                  :key="index"
                  >{{ tmp.name }}</a
                >
              </div>
              <div v-else>空</div>
            </div>
          </el-form-item>
        </div>
        <div class="box_flex">
          <el-form-item label="招投标文件:">
            <div class="contract_box">
              <div v-if="fileList2&&fileList2.length > 0" class="flex2">
                <div class="imgs2">
                  <img src="../assets/file2.png">
                </div>
                <a
                  :href="tmp.url"
                  v-for="(tmp, index) in fileList2"
                  :key="index"
                  >{{ tmp.name }}</a
                >
              </div>
              <div v-else>空</div>
            </div>
          </el-form-item>
        </div>
        <div class="box_flex">
          <el-form-item label="其他备注:">
            <textarea
              class="textarea_box"
              placeholder="空"
              disabled
              v-model="sizeForm.otherInfo"
            />
          </el-form-item>
        </div>
      </el-form>
      <!-- <div class="btn_bom">
        <div class="btn btn1" @click="resetAll">重新填写</div>
        <div class="btn btn2" @click="submit">确认提交</div>
      </div> -->
    </div>
    <div class="content5">
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import topNav from "../components/topNav";
import bottom from "../components/bottom";
import { addOrder } from "../api/api";

export default {
  components: {
    topNav,
    bottom,
  },
  data() {
    return {
      sizeForm: {
        name: "", //项目名称
        supplierName: "", //供应商名称
        sellGoodsName: "", //出售商品名称
        firstParty: "", //甲方名称
        partyB: "", //乙方
        signingTime: "", //签约时间
        money: "", //签约金额
        otherInfo: "", //其他信息
      },
      fileList: [], //上传合同
      fileList2: [], //招投标文件
    };
  },
  beforeMount() {
    let orderDetails = JSON.parse(localStorage.getItem("orderDetails"));
    this.sizeForm.name = orderDetails.name;
    this.sizeForm.supplierName = orderDetails.userName;
    this.sizeForm.sellGoodsName = orderDetails.commodityName;
    this.sizeForm.firstParty = orderDetails.partyA;
    this.sizeForm.partyB = orderDetails.partyB;
    this.sizeForm.signingTime = orderDetails.contractTime.slice(0,10);
    this.sizeForm.money = orderDetails.contractAmount;
    this.sizeForm.otherInfo = orderDetails.other;
    this.fileList = orderDetails.contractList;
    this.fileList2 = orderDetails.biddingList;

  },
  methods: {
    //上传合同
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    //上传合同文件上传后台成功时
    successBtn(res) {
      console.log(res, "res");
      console.log(this.fileList, "fileList");
    },
    //招投标文件
    handleChange2(file, fileList) {
      this.fileList2 = fileList.slice(-3);
    },
    //招投标文件上传后台成功时
    successBtn2(res) {
      console.log(res, "res");
      console.log(this.fileList2, "fileList2");
    },
    //重新填写
    resetAll() {
      this.sizeForm.name = "";
      this.sizeForm.supplierName = "";
      this.sizeForm.sellGoodsName = "";
      this.sizeForm.firstParty = "";
      this.sizeForm.partyB = "";
      this.sizeForm.signingTime = "";
      this.sizeForm.money = "";
      this.sizeForm.otherInfo = "";
      this.fileList = [];
      this.fileList2 = [];
    },
    //提交
    submit() {
      if (!this.sizeForm.name) {
        this.$message({
          showClose: true,
          message: "项目名称不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.supplierName) {
        this.$message({
          showClose: true,
          message: "供应商名称不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.sellGoodsName) {
        this.$message({
          showClose: true,
          message: "出售商品名称不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.firstParty) {
        this.$message({
          showClose: true,
          message: "甲方名称不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.partyB) {
        this.$message({
          showClose: true,
          message: "乙方名称不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.signingTime) {
        this.$message({
          showClose: true,
          message: "签约时间不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.money) {
        this.$message({
          showClose: true,
          message: "签约金额不能为空",
          type: "error",
        });
        return;
      }
      let _file = [];
      let _fileName = [];
      let _file2 = [];
      let _fileName2 = [];
      if (this.fileList) {
        this.fileList.forEach((tmp) => {
          _file.push(tmp.response.fileName);
          _fileName.push(tmp.name);
        });
        console.log(_fileName, "_fileName");
        _file = _file.join(",");
        _fileName = _fileName.join(",");
      }
      if (this.fileList2) {
        this.fileList2.forEach((tmp) => {
          _file2.push(tmp.response.fileName);
          _fileName2.push(tmp.name);
        });
        console.log(_fileName2, "_fileName2");
        _file2 = _file2.join(",");
        _fileName2 = _fileName2.join(",");
      }
      this.orderSumbit(_file2, _fileName2, _file, _fileName);
    },
    async orderSumbit(biddingUrl, biddingName, contractUrl, contractName) {
      let that = this;
      let res = await addOrder({
        commodityName: that.sizeForm.sellGoodsName,
        contractAmount: that.sizeForm.money,
        contractTime: that.sizeForm.signingTime,
        partyA: that.sizeForm.firstParty,
        partyB: that.sizeForm.partyB,
        userName: that.sizeForm.supplierName,
        name: that.sizeForm.name,
        biddingUrl: biddingUrl,
        biddingName: biddingName,
        contractUrl: contractUrl,
        contractName: contractName,
      });
      if (res.ResultCode == 0) {
        that.$message({
          showClose: true,
          message: "提交成功",
          type: "success",
        });
        setTimeout(() => {
          that.$router.go(-1);
        }, 1500);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  .nav_top {
    width: 100%;
    height: 540px;
    background-image: url("../assets/banner_mao.png");
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    .top {
      width: 100%;
      height: 100px;
      background-size: 100% 100px;
      background-repeat: no-repeat;
    }
    h1 {
      font-size: 66px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-top: 140px;
    }
    h2 {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba($color: #ffffff, $alpha: 0.8);
      text-align: center;
      margin-top: 51px;
    }
    .bom {
      margin: 0 250px;
      margin-top: 207px;
      display: flex;
      flex: 1;
      justify-content: space-between;
      .list_style {
        padding-right: 137px;
        .title {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba($color: #ffffff, $alpha: 0.8);
        }
        .title2 {
          font-size: 34px;
          font-family: DIN Alternate;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          margin-top: 25px;
        }
      }
    }
  }
  .content {
    padding-top: 110px;
    padding-bottom: 128px;
    width: 60%;
    margin: 0 auto;
    .box_flex {
      margin-bottom: 34px;
      .input_box {
        width: 100%;
        height: 40px;
        background: #f4f4f4;
        border: 1px solid #eaeaea;
        padding-left: 28px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }
      .contract_box {
        width: 100%;
        min-height: 143px;
        background: #f7f7f7;
        padding: 13px 24px;
        .contract_box_bom {
          display: flex;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          // align-items: center;
          .img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .textarea_box {
        width: 100%;
        height: 143px;
        background: #f7f7f7;
        border: 1px solid #eaeaea;
        padding: 14px 28px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 27px;
      }
    }
    .box_flex2 {
      display: flex;
      justify-content: space-between;
      width: 100%;
      //   margin-top: 34px;
      .input_box {
        width: 100%;
        height: 40px;
        background: #f4f4f4;
        border: 1px solid #eaeaea;
        padding-left: 28px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }
      .left,
      .right {
        width: 40%;
      }
      .left {
        .option_box {
          width: 100%;
          height: 40px;
          background: #f4f4f4;
          border: 1px solid #eaeaea;
          position: relative;
          .img1 {
            width: 19px;
            height: 11px;
            position: absolute;
            right: 12px;
            top: 5px;
          }
        }
      }
    }
    .btn_bom {
      display: flex;
      justify-content: center;
      .btn {
        width: 160px;
        height: 44px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 44px;
        border: none;
        text-align: center;
      }
      .btn1 {
        background: #768288;
        margin-right: 46px;
      }
      .btn2 {
        background: #2477e4;
      }
    }
  }
}
.imgs2 {
  width: 30px;
  height: 30px;
  img {
    width: 30px;
    height: 30px;
  }
}
.flex2{
    display: flex !important;
    align-items: flex-end;
}
.title3{
    line-height: 40px;
    margin-left: 25px;
}
.a_style {
  border-right: 1px solid rgba($color: #ffffff, $alpha: 0.4) !important;
}
.option_box ::v-deep .el-input--mini .el-input__inner {
  height: 40px !important;
  border: none !important;
  background: none !important;
}
.option_box ::v-deep .el-input--mini .el-input__icon {
  display: none !important;
}
.option_box ::v-deep .el-input--mini .el-input__inner {
  font-size: 14px !important;
}
</style>
